import React, { useEffect, useState } from 'react';
import { Route, Switch, BrowserRouter } from "react-router-dom";
import './App.css';
import ContactUs from "./Components/ContactUs";
import ThankYou from "./Components/ThankYou";
import Header from './Common/Header';
import Home from "./Pages/Home";
import LearnMore from './Pages/LearnMore';
import Footer from './Common/Footer';
import Automotive from "./Pages/Automotive";
import Connectivity from './Pages/Connectivity';
import WebTechnology from './Pages/WebTechnology';
const App = () => {
    const [showContactUsModal, setShowContactUsModal] = useState(false);
    const [showThankYouModal, setShowThankYouModal] = useState(false);
    return (
        <div>
            <BrowserRouter>
                {/* <ScrollToTop/> */}
                <Header setShowContactUsModal={setShowContactUsModal} />
                <ContactUs show={showContactUsModal} setShow={setShowContactUsModal} setShowThankYouModal={setShowThankYouModal} />
                <ThankYou show={showThankYouModal} setShow={setShowThankYouModal} />
                <Switch>
                    <Route exact path="/" render={props => <Home {...props} setShowContactUsModal={setShowContactUsModal} />} />
                    <Route exact path="/learn_more" render={props => <LearnMore {...props} />} />
                    <Route exact path="/auto-motive" render={props => <Automotive {...props} />} />
                    <Route exact path="/connectivity" render={props => <Connectivity {...props} />} />
                    <Route exact path="/web_technology" render={props => <WebTechnology {...props} />} />
                </Switch>
                <Footer />
            </BrowserRouter>
        </div>
    )
}

export default App;