import React, { useEffect } from 'react';
import HeroSection from "../Components/HeroSection";
import ProjectGraph from '../Components/ProjectGraph';
import Cards from '../Components/Cards';
import TimeLine from '../Components/TimeLine';
import Services from '../Components/Services';

export default function Home(props) {
    useEffect(() => {
        console.log(props);
    }, []);
    return(
        <div>
            <HeroSection/>
            <Services />
            <ProjectGraph setShowContactUsModal={props.setShowContactUsModal}/>
            <Cards/>
            <TimeLine/>
        </div>
    )
}