import React from 'react';
import Automotive_1 from "../Assets/automotive-1.svg";
import Telematics from "../Assets/Telematics_Applications.png";
import Iot_protocal from "../Assets/IoT_Protocols & Standards.png";
import Iot_OS from "../Assets/IoT_OS & Chipsets.svg";

export default function Automotive() {

  return (
    <div className="bg-blue-linear">
      <div className="container" style={{ paddingTop: "7em" }}>
        <div className="text-center text-hi-text fw-bold fs-2 py-4 ">AUTOMOTIVE</div>
        <div className="row py-5">
          <div className="col-6">
            <div className="text-hi-text fs-3 py-3">Our <span className="fw-bold">Expertise</span></div>
            <ul className="text-hi-text">
              <li> <span className="fw-bold">Along</span> with cluster and infotainment solutions we also have expertise in development of telematics applications. As a combination of IOT, Telephony and Telematics we have experience in developing plenty of applications such as remote service and car sharing service.
              </li>
              <li><span className="fw-bold">We</span> also hold expertise in integrating complete telephony stack into Automotive platforms and provide solutions like Emergency , breakdown calls.</li>
            </ul>
          </div>
          <div className="col-6">
            <img src={Automotive_1} className="w-100 pt-4" alt="Automotive" />
          </div>
        </div>
        <div className="row py-5">
          <div className="col-6">
            <img src={Telematics} className="w-75" alt="Telematics" />
          </div>
          <div className="col-6 lh-lg">
            <div className="text-hi-text fs-3 pt-5">C<span className="fw-bold">luster</span></div>
            <ul className="text-hi-text">
              <li>Digital Instrument clusters</li>
              <li>Gauges, Telltales, Chimes</li>
              <li>HVAC, Seat belt</li>
              <li>AUTOSAR</li>
            </ul>
          </div>
        </div>
        <div className="row py-5">
          <div className="col-6">
            <div className="text-hi-text fs-3 text-end pt-5 pb-3">Info<span className="fw-bold">tainment</span></div>
            <ul className="text-end text-hi-text" style={{ listStylePosition: "inside" }}>
              <li className="py-1"> <span className="fw-bold">Platform:</span> Linux, QNX, Android</li>
              <li className="py-1">HMI Developement</li>
              <li className="py-1"> <span className="fw-bold">Framework:</span> Android Telephony, Ofono</li>
              <li className="py-1">GSM/3G/4G Bluetooth</li>
              <li className="py-1">WIFI Connectivity</li>
            </ul>
          </div>
          <div className="col-6">
            <img src={Iot_protocal} className="w-75" alt="Iot_protocal" />
          </div>
        </div>
        <div className="row py-4">
          <div className="col-6">
            <img src={Iot_OS} className="w-75" alt="Iot_OS" />
          </div>
          <div className="col-6">
            <div className="text-hi-text fs-3 pt-5">Tele<span className="fw-bold">matics</span></div>
            <ul className="text-hi-text lh-lg">
              <li><span className="fw-bold">Platform:</span> Linux, Android</li>
              <li>Remote Service</li>
              <li>Telephony Stack</li>
              <li>Car Sharing</li>
              <li>Vehicle Tracking</li>
              <li>Car data upload</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
