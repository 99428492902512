import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Prev from "../Assets/prev.svg"
import Next from "../Assets/next.svg";
import CadMax from "../Assets/cadmax.svg";
import Someshwara from "../Assets/someshwara.svg";
import DigitalShark from "../Assets/digitalshark.svg";
import Suneel from "../Assets/suneel.png";
import Raju from "../Assets/raj.png";
import Javeed from "../Assets/javeed.png";
import Bhavya from "../Assets/bhavya.png";
import Saddam from "../Assets/saddam.png";
import Pavithra from "../Assets/pavithra.png";
export default function Cards() {
    let EmployeeCards = [
        {
            "id": 1,
            "colon": " “ ",
            "content": "I feel more at home, more comfortable, more in control and more supported than I ever have before. This employer genuinely cares for your growth, both professionally and personally. I love it!",
            "profile": Suneel,
            "position": "Senior Software Engineer",
            "name": "Suneel S"
        },
        {
            "id": 2,
            "colon": " “ ",
            "content": "Best place to learn new thing and if we are struck with a problem everyone try to help. And I feel it like family.",
            "profile": Raju,
            "position": "Senior Software Engineer",
            "name": "Raj Kumar"
        },
        {
            "id": 3,
            "colon": " “ ",
            "content": "Hyconsoft is one of the best Software Development company in Bangalore. Best place to work and learn about  Web Development, android and iOS. Friendly environment to work.",
            "profile": Bhavya,
            "position": "Associate Software Engineer",
            "name": "Bhavya R S"
        },
        {
            "id": 4,
            "colon": " “ ",
            "content": "I'm very pleased to be part of Hyconsoft. Hyconsoft Provided me opportunities and guided whenever needed. It is a great place to work as it is a diverse organization with talented people.",
            "profile": Javeed,
            "position": "Associate Software Engineer",
            "name": "Javeed T S"
        },
        {
            "id": 5,
            "colon": " “ ",
            "content": "Excellent working Culture and Management is very supportive, friendly. Teammates are supportive and the management is transparent. Company policies are good.",
            "profile": Saddam,
            "position": "Associate Software Engineer",
            "name": "Saddam T S"
        },
        {
            "id": 6,
            "colon": " “ ",
            "content": "I'm very happy to be in this company. I feel more comfortable and supported. I have received more positive feedback and recognition here. My personal confidence level has gone up considerably.",
            "profile": Pavithra,
            "position": "Associate Software Engineer",
            "name": "Pavithra G"
        }
    ]

    let CompanyCard = {
        logo: [
            {
                "id": 1,
                "profile": CadMax,
                "name": "Cad Max"
            },
            {
                "id": 2,
                "profile": Someshwara,
                "name": "Someshwara"
            },
            {
                "id": 3,
                "profile": DigitalShark,
                "name": "Digital Shark"
            },
        ]
    }
    return (
        <div className="card_bg my-5">
            <div className="container card_style">
                <div className="row position-relative">
                    <div classname="col">
                        <OwlCarousel className='owl-theme' autoplayHoverPause={true} items={2} slideBy={2} autoplay={true} dots={false} nav={false} loop margin={10} nav>
                            {EmployeeCards.map((info, i) => (
                                <div class='item' key={i}>
                                    <div className="card border-0 card_radius str" style={{ height: "300px" }}>
                                        <div className="card-body">
                                            <div className="row container">
                                                <div className="col-8 lh-sm">
                                                    <ul className="list-unstyled ">
                                                        <li><div className="colon fw-bold">{info.colon}</div></li>
                                                        <li><p className="card-text text-hi-text">{info.content}</p></li>
                                                        <li><div className="text-hi-text fs-6 fw-bold pb-4 position-absolute" style={{ bottom: "0" }}>{info.position}</div></li>
                                                    </ul>
                                                </div>
                                                <div className="col-4">
                                                    <div>
                                                        <ul className="mt-5 list-unstyled lh-1">
                                                            <li>
                                                                <div style={{ width: '140px', height: '140px' }}>
                                                                    <img width="100%" height="100%" src={info.profile} alt="profile" />
                                                                </div>
                                                            </li>
                                                            <li><div className="text-hi-text text-center fw-bold pb-4 fs-5 position-absolute" style={{ bottom: "0", width: '140px' }}>{info.name}</div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </OwlCarousel>
                        <div style={{ left: 'calc(-2% - 15px)', top: '50%' }} className="position-absolute">
                            <button onClick={() => document.querySelector('.owl-prev').click()} className="owl-prev btn bg-transparent border-0">
                                <img src={Prev} alt="prev" />
                            </button>
                        </div>
                        <div style={{ left: 'calc(100%)', top: '50%' }} className="position-absolute">
                            <button onClick={() => document.querySelector('.owl-next').click()} className="btn bg-transparent border-0">
                                <img src={Next} alt="next" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row position-relative company_prof">
                    <OwlCarousel className='owl-theme' items={3} slideBy={3} dots={false} nav={false} loop margin={10}>
                        {CompanyCard.logo.map((info, i) => (
                            <div className="col item" key={i}>
                                <div className="card  card_radius">
                                    <div className="card-body justify-content-center d-flex">
                                        <img className="w-100" height="200px" src={info.profile} alt="Company Logo" />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>
                </div>
            </div>
        </div>
    )
}
