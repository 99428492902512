import React, { useState, useEffect } from 'react';
import bootstrap from "../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Location from "../Assets/location.svg";
import Call from "../Assets/call.svg";
import Email from "../Assets/email.svg";
import Map from "../Assets/MAP.svg";

export default function ContactUs(props) {
  const [myModal, setMyModal] = React.useState();
  const [firstName, setFirstName] = React.useState();
  const [lastName, setLastName] = React.useState();
  const [email, setEmail] = React.useState();
  const [phone, setPhone] = React.useState();
  const [subject, setSubject] = React.useState();
  const [message, setMessage] = React.useState();
  const [firstNameErr, setFirstNameErr] = React.useState();
  const [lastNameErr, setLastNameErr] = React.useState();
  const [emailErr, setEmailErr] = React.useState();
  const [phoneErr, setPhoneErr] = React.useState();
  const [subjectErr, setSubjectErr] = React.useState();
  const [messageErr, setMessageErr] = React.useState();

  const handleFirstName = (event) => {
    setFirstName(event.target.value);
    if (!/^\D[a-zA-Z\s]*\D{2,}$/gm.test(event.target.value)) {
      setFirstNameErr('First Name should be atleast 3 characters')
    } else {
      setFirstNameErr("");
    }
  }
  const handleLastName = (event) => {
    setLastName(event.target.value);
    if (!/^\D[a-zA-Z\s]*\D{2,}$/gm.test(event.target.value)) {
      setLastNameErr('Last Name should be atleast 3 characters')
    } else {
      setLastNameErr("");
    }
  }
  const handleEmail = (event) => {
    setEmail(event.target.value);
    let emailRegex = /^[a-zA-z0-9]+(\.[a-zA-Z0-9]+)*@([a-z0-9]+([a-z0-9]*)\.)[a-zA-Z]+$/gm;
    if (!emailRegex.test(event.target.value)) {
      setEmailErr('Please Enter the Valid Email');
    } else {
      setEmailErr("");
    }
  }
  const handlePhone = (event) => {
    setPhone(event.target.value);
    if (event.target.value.length !== 10) {
      setPhoneErr('Please Enter the Valid Mobile Number')
    } else {
      setPhoneErr("");
    }
  }

  const handleSubject = (event) => {
    setSubject(event.target.value);
    if (!/^\D[a-zA-Z\s]*\D{5,}$/gm.test(event.target.value)) {
      setSubjectErr('Subject should be atleast 5 characters')
    } else {
      setSubjectErr("");
    }
  }
  const handleMessage = (event) => {
    setMessage(event.target.value);
    if (!/^\D[a-zA-Z\s]*\D{5,}$/gm.test(event.target.value)) {
      setMessageErr('Message should be atleast 5 Characters')
    } else {
      setMessageErr("");
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setSubject("");
    setMessage("");
    props.setShow(false);
    props.setShowThankYouModal(true);
  }

  useEffect(() => {
    setMyModal(new bootstrap.Modal(document.querySelector('#contact-us'), { keyboard: false, backgrop: 'static' }));
  }, []);

  useEffect(() => {
    console.log(props);
    if (myModal) {
      if (props.show) {
        myModal.show();
      } else {
        myModal.hide();
      }
    }
  }, [props, myModal]);

  return (
    <div className="modal fade" id="contact-us" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content contact_modal">
          <button type="button" className="btn-close closeBtn" onClick={() => props.setShow(false)} aria-label="Close" ></button>
          <div class="modal-body">
            <h2 className="text-center text-hi-text fw-bold mb-5">Contact Us</h2>
            <div className="row m-0 container">
              <div className="col">
                <form onSubmit={(e) => handleSubmit(e)}>
                  <h3 className="text-hi-text">Get In Touch</h3>
                  <div className="d-flex my-3">
                    <input type="text" className="form-control py-2 me-3" placeholder="First Name" value={firstName} onChange={handleFirstName} required />
                    <input type="text" className="form-control py-2" placeholder="Last Name" value={lastName} onChange={handleLastName} required />
                  </div>
                  <div className="text-danger">{firstNameErr}</div>
                  <div className="text-danger">{lastNameErr}</div>
                  <div className="my-3"><input type="text" className="form-control py-2" placeholder="Email Address" value={email} onChange={handleEmail} required />
                    <div className="text-danger">{emailErr}</div>
                  </div>
                  <div className="my-3"><input type="number" className="form-control py-2" placeholder="Phone Number" value={phone} onChange={handlePhone} required />
                    <div className="text-danger">{phoneErr}</div>
                  </div>
                  <div className="my-3"><input type="text" className="form-control py-2" placeholder="Subject" value={subject} onChange={handleSubject} required />
                    <div className="text-danger">{subjectErr}</div>
                  </div>
                  <div className="my-3">
                    <div class="form-floating">
                      <textarea class="w-100 form-control p-2" placeholder="Leave a comment here" style={{ height: "80px" }} value={message} onChange={handleMessage} required></textarea>
                      <div className="text-danger">{messageErr}</div>
                    </div>
                  </div>
                  <div className="d-grid my-4">
                    <button type="submit" className="btn btn-hi-primary">Submit </button>
                  </div>
                </form>
              </div>
              <div className="col">
                <div className="py-5">
                  <ul style={{ listStyle: "none" }}>
                    <li className="text-hi-text d-flex">
                      <img src={Location} alt="Location" className="pe-3 location" />
                      <div > MIG19,1st Floor,Sharada Arcade, Hoysala Circle, Kengeri
                        Satellite Town, Bengaluru, India 560060</div>
                    </li>
                    <li className="text-hi-text py-2"><span className="pe-3"><img src={Call} alt="Call" /></span>(+91) 9916461510</li>
                    <li className="text-hi-text py-2"><span className="pe-3"><img src={Email} alt="Email" /></span>contact@hyconsoft.in</li>
                    <li className="text-hi-text py-2"><img src={Map} alt="map" className="map" /></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}