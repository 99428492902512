import React from "react";
// import { baseUrl } from "../Config/Config.json";
import web_tech_img from "../Assets/Web Technology.svg";
import iot_img from "../Assets/Connectivity (IoT).svg";
import Automotive_img from "../Assets/Automotive.svg";
import WorldMap from "../Assets/worldmap.svg";

export default function LearnMore() {

    let TechData = {
        "data": [
            {
                "id": "1",
                "iamge": web_tech_img,
                "title": "Web Technology",
                "path": "/web_technology"
            },
            {
                "id": "2",
                "iamge": iot_img,
                "title": "Connectivity (IoT)",
                "path": "/connectivity",
            },
            {
                "id": "3",
                "iamge": Automotive_img,
                "title": "Automotive",
                "path": "/auto-motive"
            }
        ]
    }

    return (
        <div className="bg-blue-linear">
            <div className="container">
                <div className="row pt-5  w-100 text-center">
                    <div className="col mt-5 pt-3 ">
                        <p className="main-para ">Hyconsoft was founded with the aim of providing true value to clients.
                            We are achieving this<br></br>
                            by building highly capable engineering team and strong focus on R&D initiatives.</p>
                    </div>
                </div>
                <div className="row text-center justify-content-around pt-3 mt-5">
                    {TechData.data.map((item, i) => (
                        <div className="col-auto" key={i}>
                            <a href={item.path} className="link">
                                <div className="card py-3 tech-cards pb-3">
                                    <div>
                                        <img className="tech-images" src={item.iamge} alt="" />
                                    </div>
                                    <div className="text-center mt-5 pb-2">
                                        <span className="sub-titles">{item.title}</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
                <div className="row py-5 px-2">
                    <div className="col">
                        <img className="w-100 pt-5" src={WorldMap} alt="" />
                    </div>
                </div>
                <div className="row pt-5 text-center">
                    <div className="col-6">
                        <div>
                            <span className="headings">Seoul, South Korea, HQ</span>
                        </div>
                        <div>
                            <span className="address">
                                714, building A, Doosan TheLandPark 161-8, Magokjungang-ro,<br></br>
                                Gangseo-gu, Seoul, Republic of Korea
                            </span>
                        </div>
                    </div>
                    <div className="col-6">
                        <div>
                            <span className="headings">Bangalore, India, Branch</span>
                        </div>
                        <div>
                            <span className="address">
                                MIG19, 1st Floor, Sharada Arcade, Hoysala Circle, Kengeri<br></br>
                                Satellite Town, Bengaluru, India 560060
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}