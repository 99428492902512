import React from "react";
import { Link } from "react-router-dom";
import front_end_img from "../Assets/frontend.png"
import iot_img from "../Assets/Iot_web_img.png"
import ux_design from "../Assets/Frame_img.png"


export default function WebTechnology() {


    return (
        <div className="bg-blue-linear">
            <div className="container connectivity">
                <div className="row w-100 text-center align-items-center">
                    <div className="col mt-5">
                        <span className="web-title fw-bold fs-2">WEB TECHNOLOGY</span>
                    </div>
                </div>
                <div className="row mt-5 align-items-center">
                    <div className="col-6">
                        <img className="front_end_img w-100" src={front_end_img} alt="" />
                    </div>
                    <div className="col-6 text-start  ">
                        <div>
                            <span className="fs-3 frontend-development">Frontend </span>
                            <span className="fw-bold fs-3 frontend-development">Development</span>
                        </div>
                        <div>
                            <ul className="list-items">
                                <li >
                                    Vue JS
                                </li>
                                <li >
                                    React JS
                                </li>
                                <li >
                                    Typescript
                                </li>
                                <li >
                                    Bootstrap
                                </li>
                                <li >
                                    Angular
                                </li>
                                <li >
                                    W3C Web Accessibility Standards
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row mt-5 align-items-center text-end">
                    <div className="col-6  pt-2">
                        <div>
                            <span className="fs-3 frontend-development">Backend </span>
                            <span className="fw-bold fs-3 frontend-development">Development</span>
                        </div>
                        <div>
                            <ul className="list-items  list-unstyled">
                                <li>
                                <span className="fs-1 fw-bold px-2 open">.</span> Node JS
                                </li>
                                <li >
                                <span className="fs-1 fw-bold px-2 open">.</span> MangoDB/ SQL DB
                                </li>
                                <li >
                                <span className="fs-1 fw-bold px-2 open">.</span>ExpressJS
                                </li>
                                <li >
                                <span className="fs-1 fw-bold px-2 open">.</span>React API / graphQL
                                </li>
                                <li >
                                <span className="fs-1 fw-bold px-2 open">.</span>Grpc
                                </li>
                                <li >
                                <span className="fs-1 fw-bold px-2 open">.</span> PHP
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-6 ">
                        <img className="front_end_img w-100" src={iot_img} alt="" />
                    </div>
                </div>
                <div className="row mt-5 align-items-center">
                    <div className="col-6">
                        <img className="front_end_img w-75" src={ux_design} alt="" />
                    </div>
                    <div className="col-6 text-start pt-2">
                        <div>
                            <span className="fs-3 frontend-development">UI/UX </span>
                            <span className="fw-bold fs-3 frontend-development">Design</span>
                        </div>
                        <div>
                            <ul className="list-items ">
                                <li>
                                    Figma
                                </li>
                                <li>
                                    Zeplin
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}