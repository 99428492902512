import React from 'react';

export default function TimeLine() {
    return (
        <div className="times" style={{ marginTop: "13em" }}>
            <div className="timeline">
                <ul>
                    <li>
                        <div className="content">
                            <h3 className="text-hi-text">October 2020</h3>
                        </div>
                        <div className="time">
                            <p className="text-hi-text">The Hyconsoft Corporation is founded.</p>
                        </div>
                    </li>
                    <li>
                        <div className="content">
                            <h3 className="text-hi-text">Jan 2021</h3>
                        </div>
                        <div className="time">
                            <p className="text-hi-text">Participated in a LG Electronics project.</p>
                        </div>
                    </li>
                    <li>
                        <div className="content">
                            <h3 className="text-hi-text">Feb 2021</h3>
                        </div>
                        <div className="time">
                            <p className="text-hi-text"> Made partnership with LG CNS. </p>
                        </div>
                    </li>
                    <li>
                        <div className="content">
                            <h3 className="text-hi-text">March 2021</h3>
                        </div>
                        <div className="time">
                            <p className="text-hi-text">Participated in a LG Electronics project.</p>
                        </div>
                    </li>
                    <li>
                        <div className="content">
                            <h3 className="text-hi-text">April 2021</h3>
                        </div>
                        <div className="time">
                            <p className="text-hi-text">Estabilished Hyconsoft India Pvt, Ltd.<br></br>
                                Participated in a SAMSUNG SDS project.</p>
                        </div>
                    </li>
                    <div style={{ clear: "both" }}></div>
                </ul>
            </div>
            <div className="text-center continue">TO BE CONTINUED...</div>
        </div>
    )
}
