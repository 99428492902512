import React, { useEffect } from 'react';
import Graph from "../Assets/graph.svg";
export default function ProjectGraph(props) {
    useEffect(() => {
        console.log(props);
    }, []);
    return (
        <div className="container py-5">
            <div className="row">
                <div className="col">
                    <ul className="list-unstyled">
                        <li className="fs-1 fw-bold text-hi-text"><div>Have a project to</div>
                            discuss?
                        </li>
                        <li className="fs-4 text-hi-text py-2"><p>Would you like to learn more about what we do? Do you have a project you would like to discuss? If so then please submit your response and we will contact you shortly.</p></li>
                        <li><button type="button" onClick={() => props.setShowContactUsModal(true)} className="btn btn-hi-primary text-white px-4">Click here</button></li>
                    </ul>
                </div>
                <div className="col">
                    <img className="w-100" src={Graph} alt="Graph" />
                </div>
            </div>
        </div>
    )
}
