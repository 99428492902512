import React, { useState, useEffect } from 'react';
import bootstrap from "../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import ThankYouImage from "../Assets/thankyou.svg";

export default function ThankYou(props) {
    const [myModal, setMyModal] = React.useState();
    useEffect(() => {
        setMyModal(new bootstrap.Modal(document.querySelector('#thank-you'), { keyboard: false, backgrop: 'static' }));
        console.log(myModal);
    }, []);

    useEffect(() => {
        console.log(props);
        if (myModal) {
            if (props.show) {
                myModal.show();
                setTimeout(() => {
                    props.setShow(false);
                }, 2000);
            } else {
                myModal.hide();
            }
        }
    }, [props.show, myModal]);

    return (
        <div class="modal fade" id="thank-you" tabindex="-1" aria-labelledby="thank-you" aria-hidden="true">
            <div class="modal-dialog modal-dialog">
                <div class="modal-content" style={{ borderRadius: "25px", backgroundColor: "#E7F0FF" }}>
                    <div class="modal-body py-5 text-center">
                        <div>
                            <img src={ThankYouImage} className="w-25" alt="ThankYou" />
                        </div>
                        <div className="text-hi-text fs-2 fw-bold py-2">Thank You!</div>
                        <div className="text-hi-text">Your submission has been received.</div>
                    </div>
                </div>
            </div>
        </div>
    );
}