import React from "react"
import Staff_Augmentation from "../Assets/Staff_Augmentation.svg";
import Fixed_Cost_Based from "../Assets/Fixed_Cost_Based.svg";
import Technical_Consultation from "../Assets/Technical_Consultation.svg";

export default function Services() {

    let ServicesData = {
        "Services": [
            {
                "id": 1,
                "img": Staff_Augmentation,
                "title": "Staff Augmentation",
                "des": "We partner with clients to derive customized staffing solutions to achieve your organizational goals"
            },
            {
                "id": 2,
                "img": Fixed_Cost_Based,
                "title": "Fixed Cost Based",
                "des": "We provide Fixed Price IT solution development services. Under this model we ensure delivering software solution at a fixed or pre-determined rate."
            },
            {
                "id": 3,
                "img": Technical_Consultation,
                "title": "Technical Consultation",
                "des": "We provide a complete suite of services as part of our technology consulting service. We take the journey with clients from the first stage of conceptualization to the last stage of maintenance"
            }
        ]
    }
    return (
        <div className="services-section">
            <div className="container">
            <div className="row m-0 my-5">
                <div className="row mb-4">
                    <div className="col-12 text-center">
                        <h2 className="services-title">SERVICES</h2>
                    </div>
                </div>
                <div className="row">
                    {ServicesData.Services.map((info, i) => (
                        <div className="col-4 text-center px-4" key={i}>
                            <img src={info.img} alt=""  className="services-img"/>
                            <h4 className="services-sub-title mb-3 mt-3">{info.title}</h4>
                            <span className="services-des">{info.des}</span>
                        </div>
                    ))}
                </div>
            </div>
            </div>
        </div>
    )
}