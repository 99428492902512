import React from 'react';
// import { baseUrl } from "../Config/Config.json";
import "../App.css";
import Logo from "../Assets/LOGO.svg"

export default function Header(props) {
  const [head, setNav] = React.useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setNav(true);
    } else {
      setNav(false);
    }
  }
  window.addEventListener('scroll', changeBackground);

  return (
    <div>
      <nav className={head ? "navbar navbar-expand-lg position-fixed w-100 max-height-60px actives" : "navbar navbar-expand-lg navbar-light bg-transprent position-fixed w-100 max-height-60px"} style={{ zIndex: "100" }}>
        <div className="container">
          <a className="navbar-brand" href={"/"}>
            <img src={Logo} width="120px" alt="Logo" /></a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex align-items-center">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#"><button onClick={() => props.setShowContactUsModal(true)} type="button" className="btn btn-hi-primary">Contact Us</button></a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}
