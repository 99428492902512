import React from "react";
// import { Link } from 'react-router-dom';
// import { baseUrl } from "../Config/Config.json";
import hero from "../Assets/hero.svg";

export default function HeroSection() {
  return (
    <div style={{ paddingTop: "10em" }} className="container-fluid hero">
      <div className="container">
        <div className="row ">
          <div className="col">
            <ul className="list-unstyled ">
              <li className="text-hi-text fs-1 fw-bold">
                <div>Enabling Hyper</div>
                <div>Connected Society</div>
              </li>
              <li className="text-hi-text py-4 fs-3"><p>By connecting people-people and
                people-technology, through innovative
                solutions and strategic partnership</p></li>
              <li>
                <a href={"/learn_more"} className="text-decoration-none text-white">
                  <button className="btn btn-hi-primary text-white px-3 my-4">Learn More</button>
                </a>
              </li>
            </ul>
          </div>
          <div className="col">
            <img src={hero} alt=" Hero" className="w-100" />
          </div>
        </div>
      </div>
    </div>
  );
}
