import React from "react"
import expertise from "../Assets/expertise.png";
import Iot from "../Assets/Iot.png";
import IotC from "../Assets/IotC.png";
import Iots from "../Assets/Iots.png";
export default function Connectivity() {
    return (
        <div className="bg-blue-linear">
            <div className="container connectivity">
                <div className="row">
                    <div className="col text-center mt-5">
                        <span className="text-hi-text fs-2 fw-bold">CONNECTIVITY</span>
                    </div>
                </div>
                <div className="row mt-5 align-items-center">
                    <div className="col-6 text-hi-text">
                        <div>
                            <span className="text-hi-text fs-3">Our </span><span className="text-hi-text fs-3 fw-bold">Expertise</span>
                        </div>
                        <div className="px-1 mt-3">
                            <ul>
                                <li className="connect-des"><span className="fw-bold">Hyconsoft </span> exhibits expertise across different IoT Verticals which includes device, gateway and cloud communications. We have in depth knowledge in integrating IOT solutions across different platforms.</li>
                                <li className="connect-des"><span className="fw-bold">Currently </span>
                                    we have hands on experience on Automotive and Home Automation applications in IoT. With our knowledge on embedded systems, different sensors and different platforms enable us to execute complete IoT projects.We also have experience in developing applications using different communication protocols like COAP, MQTT etc.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-6">
                        <img src={expertise} alt="" className="w-100" />
                    </div>
                </div>
                <div className="row align-items-center ">
                    <div className="col-6">
                        <img src={Iot} alt="" className="w-100" />
                    </div>
                    <div className="col-6 text-hi-text">
                        <div>
                            <span className="fs-3">Internet </span><span className="fs-3 fw-bold">of Things</span>
                        </div>
                        <div>
                            <ul>
                                <li>Automotive telemetics apps</li>
                                <li>Home applicances control</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center ">
                    <div className="col-6 text-hi-text text-end">
                        <div>
                            <span className="fs-3">Protocols </span><span className="fs-3 fw-bold"> & Standards</span>
                        </div>
                        <div>
                            <ul className="list-unstyled">
                                <li><span className="fs-1 fw-bold px-2 open">.</span>Open Connectivity Foundation [IoTivity]</li>
                                <li><span className="fs-1 fw-bold px-2 open">.</span>MQTT / ZeroMQ</li>
                                <li><span className="fs-1 fw-bold px-2 open">.</span>CoAP</li>
                                <li><span className="fs-1 fw-bold px-2 open">.</span>HTTP / HTTPS</li>
                                <li><span className="fs-1 fw-bold px-2 open">.</span>REST</li>
                                <li><span className="fs-1 fw-bold px-2 open">.</span>DTLS / TLS / ACL</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-6">
                        <img src={Iots} alt="" className="w-100" />
                    </div>
                </div>
                <div className="row align-items-center ">
                    <div className="col-6">
                        <img src={IotC} alt="" className="w-100" />
                    </div>
                    <div className="col-6 text-hi-text">
                        <div>
                            <span className="fs-3">OS</span><span className="fs-3 fw-bold"> & Chipsets</span>
                        </div>
                        <div>
                            <ul>
                                <li>Android</li>
                                <li>Linux / YOCTO</li>
                                <li>INTEL</li>
                                <li>Mediatek</li>
                                <li>Qualcomm</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}