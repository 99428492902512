import React from 'react';
// import { Link } from 'react-router-dom';
// import { baseUrl } from "../Config/Config.json";
import Logo from "../Assets/FootLogo.svg";
// import insta from "../Assets/Instagram Copy 3.svg";
// import facebook from "../Assets/facebook.svg";
// import twitter from "../Assets/twitter.svg";
// import instagram from "../Assets/instagram.svg";
// import youtube from "../Assets/youtube.svg";

export default function Footer() {
  return (
    <div className="footer py-4 mt-5">
      <div className="row  container m-auto  pt-5  m-0">
        <div className="col">
          <div>
            <a href={"/"} className="text-decoration-none">
              <img src={Logo} width="100px" alt="logo" />
            </a>
          </div>
          <div className="py-3">
            {/* <p className="text-white">
                Fusce ut elit aliquet, fermentum leo vel, tempus nunc. Fusce eu
                rhoncus augue. Fusce vel metus pharetra, fermentum
              </p> */}
            {/* <div className="media d-flex justify-content-around ">
                <img src={insta} alt="" />
                <img src={facebook} alt="" />
                <img src={instagram} alt="" />
                <img src={youtube} alt="" />
                <img src={twitter} alt="" />
              </div> */}
          </div>
        </div>
        <div className="col">
          <div className="fw-bold text-white px-4">QUICK LINKS</div>
          <ul className="quick-links text-white py-3">
            <li className="text-start py-2">
              <a href={"/"} class="text-decoration-none text-white">Home</a>
            </li>
            <li className="text-start py-2">
              <a href={"/web_technology"} class="text-decoration-none text-white">Web Technology</a>
            </li>
            <li className="text-start py-2">
              <a href={"/connectivity"} class="text-decoration-none text-white">Connectivity (IoT)</a>
            </li>
            <li className="text-start py-2">
              <a href={"/auto-motive"} class="text-decoration-none text-white">Automotive</a>
            </li>
          </ul>
        </div>
        <div className="col">
          <div className="fw-bold text-white px-4">COMPANY</div>
          <ul className="quick-links text-white py-3">
            <li className="py-2">
              <a href={"/learn_more"} class="text-white text-decoration-none">About Us</a>
            </li>
            {/* <li className="py-2">Cookie Policy</li>
              <li className="py-2">Terms & Conditions</li> */}
          </ul>
        </div>
        <div className="col ">
          <div className="fw-bold text-white px-4">CONTACT</div>
          <ul className="quick-links text-white py-3">
            <li classname="py-2">contact@hyconsoft.in</li>
            <li className="py-2">(+91) 9916461510</li>
            <li className="py-2">Address goes here</li>
            <li>
              MIG19, 1st Floor, Sharada Arcade, Hoysala Circle, Kengeri
              Satellite Town, Bengaluru, India 560060
            </li>
          </ul>
        </div>

      </div>
      <div className="hr container"></div>
      <div className="text-center text-white mt-4">© 2021  Copyright by Hyconsoft Technologies Private Limited.</div>
    </div>
  )
}
